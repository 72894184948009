/* //Add Logo Script Font */
@font-face {
  font-family: "Freestyle Script";
  src: local("Freestyle Script"), url(./assets/FREESCPT.TTF) format("truetype");
}

/* body { */
/* Disables pull-to-refresh but allows overscroll glow effects. */
/* overscroll-behavior-y: contain; */
/* overscroll-behavior-y: none; */
/* } */

:root {
  --nav-main: #94070a;
  --nav-sub: #f7a19a;
  --nav-highlight: #faa61a;
  --nav-highlight-sub: #fdc578;
  --nav-font-color: #ffff;
  --nav-grey: #98999d;
  --nav-focus-0: rgba(250, 166, 26, 0);
  --nav-focus-100: rgba(250, 168, 26, 0.3);
  --nav-scrollbar: rgba(255, 255, 255, 0.85);

  --section-title-color: #211d1f;
  --section-title-font: "Qlikview Sans - Bold";
  --section-title-size: 24pt;

  --subsection-title-color: #55565a;
  --subsection-title-font: "Qlikview Sans";
  --subsection-title-size: 20pt;

  --viz-title-color: #77787c;
  --viz-title-font: "Qlikview Sans";
  --viz-title-size: 16pt;

  --virtual-assistant-color: #ffbfbf;
  --virtual-assistant-size: 18pt;

  --card-title-color: rgb(119, 120, 124);
}

.euiText-hover {
  cursor: pointer;
  padding: 4px;
  border-radius: 5px;
}

.euiText-hover:hover {
  background-color: #e3e5e9;
}

/*^ Set Tabs Globaly */
.euiTabs button.euiTab {
  background-color: var(--nav-sub);
  border-left: 1px solid var(--nav-font-color);
  border-right: 1px solid var(--nav-font-color);
  /* border-radius: 10px 10px 0 0; */
  margin-left: 0px;
  padding-left: 15px;
  padding-right: 15px;
}

.euiTabs button.euiTab.euiTab-isSelected {
  background-color: var(--nav-main);
  box-shadow: inset 0 -2px 0 var(--nav-sub);
}

.euiTabs button.euiTab > span {
  color: var(--nav-font-color) !important;
}

/*^ VizLib Loading Object*/
/* TODO: Come back and adjust this. */
.vzb-x-loading-indicator-text {
  visibility: hidden !important;
}
.vzb-x-loading-indicator-text:after {
  visibility: visible;
  color: var(--nav-main);
  content: "Loading Enolytics Chart";
  display: block;
  position: absolute;
}

/*^ EUI Checkbox color change */
.euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
  background-color: var(--nav-highlight) !important;
  border-color: var(--nav-highlight) !important;
}

/* Keep Checkbox label together */
.QlikSelectionControl .euiCheckbox__label {
  white-space: nowrap;
}

#launcher-frame {
  max-width: 90px !important;
  min-width: 90px !important;
}
