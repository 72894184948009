.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #94070a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-text {
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: white;
  margin-top: 40px;
  z-index: 0;
}

.icon-container {
  width: 400px;
  height: 100px;
}

.icon {
  width: 100%;
  height: auto;
  animation: fade 20s ease-in-out infinite;
  margin-top: 20px;
}
.bubbles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  /*   background:#29b6f6; */
  background: transparent;
}
.bubble {
  position: absolute;
  bottom: -100px;
  width: 40px;
  height: 40px;
  /* background: #f1f1f1;
    border-radius: 50%;*/
  opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: black;
  animation: rise 5s infinite ease-in;
}
.bubble:nth-child(1) {
  width: 40px;
  height: 40px;
  left: 10%;
  animation-duration: 3s;
  background: #f1f1f1;
  border-radius: 50%;
}
.bubble:nth-child(2) {
  width: 20px;
  height: 20px;
  left: 20%;
  /* animation-duration: 5s; */
  animation-delay: 1s;
}
.bubble:nth-child(3) {
  width: 80px;
  height: 80px;
  left: 50%;
  animation-duration: 4s;
  animation-delay: 0s;
  background: #f1f1f1;
  border-radius: 50%;
}
.bubble:nth-child(4) {
  width: 35px;
  height: 35px;
  left: 55%;
  animation-duration: 6s;
  animation-delay: 1s;
  background: #f1f1f1;
  border-radius: 50%;
}
.bubble:nth-child(5) {
  width: 45px;
  height: 45px;
  left: 65%;
  animation-duration: 3s;
  animation-delay: 1s;
  background: #f1f1f1;
  border-radius: 50%;
}
.bubble:nth-child(6) {
  width: 90px;
  height: 90px;
  left: 70%;
  animation-duration: 4s;
  animation-delay: 2s;
  background: #f1f1f1;
  border-radius: 50%;
}
.bubble:nth-child(7) {
  width: 50px;
  height: 50px;
  left: 90%;
  animation-duration: 5s;
  animation-delay: 2s;
}

@keyframes rise {
  0% {
    bottom: -100px;
    transform: translateX(0);
  }
  50% {
    transform: translate(100px);
  }
  100% {
    bottom: 1080px;
    transform: translateX(-200px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes fade {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

/* NEW BUBBLES */

@keyframes rise {
  0% {
    bottom: -100px;
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px);
  }
  100% {
    bottom: 100vh;
    transform: translateX(-200px);
  }
}

.newBubble {
  position: absolute;
  bottom: -100px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  animation: rise linear infinite;
}

.newBubble::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 20px;
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}
